import { Trans } from "next-i18next"

export const desktopContent = t => {
    return [
        {
            id: 0,
            titleImage: 'https://storage.googleapis.com/base-lp-assets/12%2012.png',
            titleImageMobile: 'https://storage.googleapis.com/base-lp-assets/12%2012.png',
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/mobile_banner_12.12-2.png',
            image: 'https://storage.googleapis.com/base-lp-assets/banner-50.png',
            titleImageAlt: 'New year bundle 1',
            title: '',
            containerStyle: 'container1',
            imageStyle: 'imageStyle1',
            subtitleStyles: 'customStyle1',
            subtitle: t('landing:hero.10.subtitle'),
            colorCode: '#603A37',
            productSlug: '/',
            colorMode: 'dark',
            ctaAlign: 'center',
        },
        {
            id: 1,
            titleImage: '',
            titleImageMobile: '',
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/mobile_banner_the_limited_edition_holiday_gift_set.png',
            image: 'https://storage.googleapis.com/base-lp-assets/DTC_Base%2012.12%20%26%20Holiday%20Pouch%20no%20CTA-DESKTOP-2.png',
            titleImageAlt: 'New year bundle 2',
            title: <Trans i18nKey={'landing:hero.9.title'}><br/></Trans>,
            titleStyle: 'titleHero2',
            containerStyle: 'container2',
            addtionalComponent: true,
            subtitle: t('landing:hero.9.subtitle'),
            contentCopy: t('landing:hero.9.title'),
            subtitleStyles: 'customStyle2',
            colorCode: '#FCE8DB',
            productSlug: '/',
            colorMode: 'light',
            ctaAlign: 'center',
        },
        {
            id: 2,
            titleImage: '',
            titleImageMobile: '',
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/Cleanser%20-%20DTC%20HPB-02.jpg',
            image: 'https://storage.googleapis.com/base-lp-assets/output.lin.jpg',
            titleImageAlt: 'Centamide Gentle Hydrating Cleanser',
            title: <Trans i18nKey={'landing:hero.6.title'}><br/></Trans>,
            description: t('landing:hero.6.description'),
            colorCode: '#E5E5E5',
            productSlug: '/cleanser/centamide-gentle-hydrating-cleanser',
            colorMode: 'dark',
            ctaAlign: 'flext-start',
        },
        {
            id: 3,
            titleImage: 'https://storage.googleapis.com/base-lp-assets/Beauty%20in%20balance%20-%20DTC.png',
            titleImageMobile: 'https://storage.googleapis.com/base-lp-assets/Beauty%20in%20balance%20-%20DTC.png',
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/Bodyscrub%20-%20DTC-19.jpg',
            image: 'https://storage.googleapis.com/base-lp-assets/Bodyscrub%20-%20DTC-16.jpg',
            titleImageAlt: 'BASE x FEMALE DAILY Body scrub',
            title: '',
            description: t('landing:hero.7.description'),
            colorCode: '#E5E5E5',
            productSlug: '/body-scrub/base-illipe-&-apricot-brightening-body-scrub',
            colorMode: 'light',
            ctaAlign: 'flext-start',
        },
        {
            id: 4,
            imageMobile: 'https://storage.googleapis.com/base-lp-assets/M%20BANNER%201.png',
            image: 'https://storage.googleapis.com/base-lp-assets/Banner%201%20Desktop.png',
            titleImage: 'https://storage.googleapis.com/base-lp-assets/gloss-n-go-title.png',
            titleImageMobile: 'https://storage.googleapis.com/base-lp-assets/gloss-n-go-title.png',
            titleImageAlt: 'liptint',
            title: <Trans i18nKey={'landing:hero.1.title'}><br/></Trans>,
            description: t('landing:hero.1.description'),
            colorCode: '#E5E5E5',
            productSlug: '/lip-tint/base-gloss-and-go-lip-tint',
            colorMode: 'dark',
             ctaAlign: 'flext-start',
        },
    ]
}
